exports.components = {
  "component---gatsby-theme-elemental-src-pages-404-js": () => import("./../../../../gatsby-theme-elemental/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-pages-404-js" */),
  "component---gatsby-theme-elemental-src-pages-contact-js": () => import("./../../../../gatsby-theme-elemental/src/pages/contact.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-pages-contact-js" */),
  "component---gatsby-theme-elemental-src-pages-index-js": () => import("./../../../../gatsby-theme-elemental/src/pages/index.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-pages-index-js" */),
  "component---gatsby-theme-elemental-src-pages-wines-js": () => import("./../../../../gatsby-theme-elemental/src/pages/wines.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-pages-wines-js" */),
  "component---gatsby-theme-elemental-src-templates-basepage-js": () => import("./../../../../gatsby-theme-elemental/src/templates/basepage.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-templates-basepage-js" */),
  "component---gatsby-theme-elemental-src-templates-blog-js": () => import("./../../../../gatsby-theme-elemental/src/templates/blog.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-templates-blog-js" */),
  "component---gatsby-theme-elemental-src-templates-blog-list-js": () => import("./../../../../gatsby-theme-elemental/src/templates/blog-list.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-templates-blog-list-js" */),
  "component---gatsby-theme-elemental-src-templates-portfolio-js": () => import("./../../../../gatsby-theme-elemental/src/templates/portfolio.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-templates-portfolio-js" */),
  "component---gatsby-theme-elemental-src-templates-portfolio-list-js": () => import("./../../../../gatsby-theme-elemental/src/templates/portfolio-list.js" /* webpackChunkName: "component---gatsby-theme-elemental-src-templates-portfolio-list-js" */)
}

